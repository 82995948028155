import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../css/components/gamecard.scss"
import Image from "../components/image"

const GameCard = ({ title, description, link, gifImg, img }) => (
  <Link className="gamecard" to={link}>
    {img && (
      <div className="bg-image" style={{ backgroundImage: `url(${img})` }} />
    )}
    {gifImg && (
      <div className="bg-image" style={{ backgroundImage: `url(${gifImg})` }} />
    )}
    <div className="content-bg" />
    <div className="content">
      <h1>{title}</h1>
      <p>{description}</p>
      <div className="chevron-wrapper">
        <div className="chevron" />
      </div>
    </div>
  </Link>
)

GameCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  gifImg: PropTypes.string,
  img: PropTypes.string,
}

GameCard.defaultProps = {
  title: ``,
  description: ``,
  link: ``,
  image: ``,
}

export default GameCard
