import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/games.scss"
import GameCard from "../components/gamecard"
import arcadeGIF1 from "../images/presskit/arcade-gif-2.gif"
import arcadeImg from "../images/presskit/arcade (1).png"
import wallpunkImg from "../images/presskit/wallpunk-bg.png"

import CardContainer from "../components/cardcontainer"

const Games = () => (
  <Layout showFooter={false}>
    <SEO title="Games" />
    <div className="main">
      <div className="games">
        <div className="cards">
          <div>
            <CardContainer>
              <GameCard
                title="Project Arrhythmia"
                description={`"Project Arrhythmia" is a musical bullet-hell in which all things beautiful are deadly. Avoid the musically timed pulse of blocks and bars, and in the process you might just find out what this mysterious "Project Arrhythmia" is.`}
                link="/project-arrhythmia"
                gifImg={arcadeGIF1}
                img={arcadeImg}
              />
              <GameCard
                title="WallPunk"
                description="A brand new VR Rhythm / Action game coming soon!"
                link="/wall-punk"
                img={wallpunkImg}
              />
            </CardContainer>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Games
